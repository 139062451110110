.bg-poppinpay {
  background: #2D9CDB;
}
.bg-neutral {
  background: #ececec;
}



	.st0{fill:#63666A;}
	.st1{fill:#43B02A;}